<template>
  <div v-loading="loading">
    <div ref="screen" class="input">
      <!--    筛选框 开始-->
      <el-row class="screen">
        <el-form inline :label-width="screenLabelWidth">
          <el-row class="theader">
            <el-form-item label="姓名" class="theader">
              <el-input v-model="data.params.popName" @change="searchChange" filterable clearable size="mini" class="screenInput"></el-input>
            </el-form-item>
            <el-form-item label="电话">
              <el-input v-model="data.params.popTelephone" @change="searchChange" filterable clearable size="mini" class="screenInput"></el-input>
            </el-form-item>
            <el-form-item label="身份证">
              <el-input v-model="data.params.popIdcard" @change="searchChange" filterable clearable size="mini" class="screenInput"></el-input>
            </el-form-item>
            <el-form-item label="客户类型">
              <el-select v-model="data.params.jsrrCustomerType" @change="searchChange" clearable size="mini" class="screenInput">
                <el-option label="散客" value="散客"></el-option>
                <el-option label="团客" value="团客"></el-option>
                <el-option label="会员" value="会员"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item style="margin-left: 10px">
              <el-button type="primary" @click="advancedScreening" size="mini">高级筛选</el-button>
            </el-form-item>
          </el-row>
          <el-row v-show="screenState">
            <el-form-item label="状态">
              <el-select v-model="data.params.jsrcState" @change="searchChange" filterable clearable size="mini" class="screenInput">
                <el-option label="已住" value="已住"></el-option>
                <el-option label="离开" value="离开"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="登记人">
              <v-cascader
                :options="userNameList"
                v-model.lazy="data.userId"
                @change="userNameChange"
                :strictly="true"
                filterable
                clearable
                :props="{ expandTrigger: 'hover' }"
                size="mini"
                style="width:190px"
              ></v-cascader>
            </el-form-item>
            <el-form-item label="入住时间">
              <el-date-picker
                v-model="data.time"
                @change="dateChange"
                :unlink-panels="true"
                filterable
                clearable
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                size="mini"
              ></el-date-picker>
            </el-form-item>
          </el-row>
        </el-form>
      </el-row>
      <!--    筛选框 结束-->

      <!--    会员信息弹框 开始-->
        <el-dialog title="会员信息" :visible.sync="vip.dialogVip" @close="closeDialogVip" :close-on-click-modal="false" width="900px" class="screen">
          <el-form inline :label-width="screenLabelWidth">

            <el-row>
              <el-col :span="18">
                <el-row>
                  <el-form-item label="渠道类型">
                    <el-input v-model="vip.params.jcuType" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                  <el-form-item label="群体分类">
                    <el-input v-model="vip.params.jcuGroupType" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                  <el-form-item label="会员卡号">
                    <el-input v-model="vip.params.cardNumber" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item label="客户姓名">
                    <el-input v-model="vip.params.popName" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                  <el-form-item label="备注">
                    <el-input v-model="vip.params.popNameRemark" disabled size="mini" style="width:335px"></el-input>
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item label="证件类型">
                    <el-input v-model="vip.params.popIdcardType" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                  <el-form-item label="证件号码">
                    <el-input v-model="vip.params.popIdcard" disabled size="mini" style="width:335px"></el-input>
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item label="生日">
                    <el-input v-model="vip.params.popBirth" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                  <el-form-item label="户籍地址">
                    <el-input v-model="vip.params.popIdcardAddress" disabled size="mini" style="width:335px"></el-input>
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item label="性别">
                    <el-input v-model="vip.params.popSex" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                  <el-form-item label="民族">
                    <el-input v-model="vip.params.popNation" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                  <el-form-item label="联系电话">
                    <el-input v-model="vip.params.popTelephone" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                </el-row>
              </el-col>
              <el-col :span="5">
                <div class="block" style="text-align: center;">
                  <el-image :src="vip.params.popImgPath" style="width:180px;height:225px;margin:7px">
                    <div slot="error" class="image-slot">
                      <img src="~images/userImage.png" alt="">
                    </div>
                  </el-image>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-row>
                <el-form-item label="入住次数">
                  <el-input v-model="vip.params.jsrrCheckInNum" disabled size="mini" class="screenInput"></el-input>
                </el-form-item>
                <el-form-item label="内外信用">
                  <el-input v-model="vip.params.popInnerCreditLevel" disabled size="mini" style="width:58px;margin-right:5px"></el-input>
                  <el-input v-model="vip.params.popOuterCreditLevel" disabled size="mini" style="width:57px;margin-left:5px"></el-input>
                </el-form-item>
                <el-form-item label="会员积分">
                  <el-input v-model="vip.params.integral" disabled size="mini" class="screenInput"></el-input>
                </el-form-item>
                <el-form-item label="车牌号码">
                  <el-input v-model="vip.params.licensePlate" disabled size="mini" class="screenInput"></el-input>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="微信绑定">
                  <el-input v-model="vip.params.weixin" disabled size="mini" class="screenInput"></el-input>
                </el-form-item>
                <el-form-item label="黑名单">
                  <el-input v-model="vip.params.blackList" disabled size="mini" class="screenInput"></el-input>
                </el-form-item>
                <el-form-item label="初始来源">
                  <el-input v-model="vip.params.initialSource" disabled size="mini" class="screenInput"></el-input>
                </el-form-item>
                <el-form-item label="会员余额">
                  <el-input v-model="vip.params.balance" disabled size="mini" class="screenInput"></el-input>
                </el-form-item>
              </el-row>
            </el-row>

          </el-form>
          <el-table
          :data="vip.params.shortRentContractDTOS"
          ref="vipHighLightRow"
          highlight-current-row
          @current-change="vipCurrentChange"
          @row-dblclick="orderDetailDialog = true"
          height="216px"
          size="mini"
          style="width=100%;" >
            <el-table-column align="center" prop="address" label="入住客房" min-width="100px"></el-table-column>
            <el-table-column align="center" prop="jsrcBeginTime" label="入住时间" width="150px"></el-table-column>
            <el-table-column align="center" prop="jsrcEndTime" show-overflow-tooltip label="退房时间" width="150px"></el-table-column>
            <el-table-column align="center" prop="jsrcState" label="入住状态" min-width="100px"></el-table-column>
          </el-table>
          <div slot="footer" style="text-align: center">
            <el-button @click="changeVipRowData(false)">上一条</el-button>
            <el-button @click="vip.dialogVip = false">取 消</el-button>
            <el-button @click="changeVipRowData(true)">下一条</el-button>
          </div>
        </el-dialog>
      <!--    会员信息弹框 结束-->
      
      <!--    详细信息弹框 开始-->
        <order-detail
          v-if="orderDetailDialog"
          :isDialogShow="orderDetailDialog"
          :isDataList="vip.params.shortRentContractDTOS"
          :isRowData="vip.rowData"
          @nextOrPrev="nextOrPrev"
          @close="orderDetailClose"
        >
        </order-detail>
      <!--    详细信息弹框 结束-->
    </div>
    <!--    数据列表 开始-->
    <div style="text-align:center;">
      <!--    表格显示列表 开始-->
      <el-table
      :data="dataList"
      ref="highLightRow"
      v-loading="indexLoading"
      highlight-current-row
      @current-change="currentChange"
      @row-dblclick="vipInformation"
      :height="tableHeight"
      style="width=100%;">
        <el-table-column align="center" prop="popName" label="姓名" min-width="25px"></el-table-column>
        <el-table-column align="center" prop="popNameRemark" label="备注" show-overflow-tooltip min-width="50px"></el-table-column>
        <el-table-column align="center" prop="popTelephone" label="电话号码" min-width="35px"></el-table-column>
        <el-table-column align="center" prop="popIdcard" label="身份证" width="165px"></el-table-column>
        <el-table-column align="center" prop="jsrrCheckInNum" label="入住次数" min-width="30px"></el-table-column>
        <el-table-column align="center" prop="jsrrCustomerType" label="客户类型" min-width="25px"></el-table-column>
        <el-table-column align="center" prop="jsrrRegisterTime" label="登记日期" width="152px"></el-table-column>
        <el-table-column align="center" prop="username" label="登记人" min-width="30px"></el-table-column>
        <el-table-column align="center" prop="jsrcActualOccupancyTime" label="最近入住时间" width="152px"></el-table-column>
        <el-table-column align="center" prop="jsrcState" label="状态" min-width="30px"></el-table-column>
      </el-table>
      <!--    表格显示列表 结束-->

      <!--    分页 开始-->
      <el-pagination
        background layout="total, sizes, prev, pager, next, jumper"
        :page-count="data.pages"
        :page-size="data.params.limit"
        :total="data.total"
        :current-page="data.params.cursor"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-sizes="[5,10,20,40]"
      ></el-pagination>
      <!--    分页 结束-->
    </div>
    <!--    数据列表 结束-->
  </div>
</template>
<script>
import { mapState } from 'vuex'
import orderDetail from './components/orderDetail'
export default {
  components: {
    'order-detail': orderDetail
  },
  name: 'vip',
  data(){
    return{
      loading: false,
      indexLoading : false,
      screenState: false,
      screenLabelWidth: '75px',
      tableHeight: window.innerHeight - 210,
      vip: {
        dialogVip: false,
        params: {
          jcuType: '',//渠道类型
          jcuGroupType: '',//群体分类
          cardNumber: '',//会员卡号
          popName: '',//客户姓名
          popNameRemark: '',//备注
          popIdcardType: '',//证件类型
          popIdcard: '',//证件号码
          popBirth: '',//生日
          popIdcardAddress: '',//户籍地址
          popSex: '',//性别
          popNation: '',//民族
          popTelephone: '',//联系电话
          popImgPath: '',//图片
          jsrrCheckInNum: '',//入住次数
          popInnerCreditLevel: '',//内信用
          popOuterCreditLevel: '',//外信用
          integral: '',//会员积分
          licensePlate: '',//车牌号码
          weixin: '',//微信绑定
          blackList: '',//黑名单
          initialSource: '',//初始来源
          balance: '',//会员余额
          shortRentContractDTOS: [],
        },
        rowData: {},
      },
      orderDetailDialog: false,
      data: {//数据分页查询
        params: {
          popName: '',//姓名
          popTelephone: '',//电话
          popIdcard: '',//身份证
          jsrrCustomerType: '',//客户类型
          jsrcState: '',//状态
          suStoreId: '',//门店ID
          suDepartmentId: '',//部门ID
          popUserId: '',//登记人
          startTime: '',//入住开始时间
          endTime: '',//入住结束时间
          limit: 10,//每页的条数
          cursor: 1,//初始页
        },
        total: 0,//总条数
        pages: 0,//每页数据
        userId: [],
        time: '',
      },
      dataList: [],
      rowData: {},
      dataCityProper: [],//城区下拉菜单
      dataFloor: [],//楼栋名称下拉菜单
    }
  },
  computed: {
    ...mapState(['userName','userNameList'])
  },
  created(){
    this.getDataList()
  },
  mounted(){
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - this.$refs.screen.clientHeight - 140
    })
  },
  methods: {
  //查询数据
    advancedScreening(){//高级筛选
      this.screenState ? this.screenState = false : this.screenState = true
      this.$nextTick(() => {
        this.tableHeight = window.innerHeight - this.$refs.screen.clientHeight - 140
      })
    },
    getDataList(){//查询数据
      this.indexLoading = true
      this.$axios({
        method: 'get',
        url: '/hotel/shortRentRenter',
        params: this.data.params
      }).then(res => {
        if (res.data.result.records.length > 0) {
          this.dataList = res.data.result.records
          this.data.total = res.data.result.total
        } else {
          this.dataList = []
          this.data.total = 0
        }
        this.indexLoading = false
      }).catch(err => {
        this.indexLoading = false
        this.isError(err,this)

      })
    },
    searchChange(){//筛选条件改变时
      this.data.params.cursor = 1
      this.getDataList()
    },
    userNameChange(){//登记人改变时查询
      this.data.params.suStoreId = ''
      this.data.params.suDepartmentId = ''
      this.data.params.popUserId = ''
      if (this.data.userId.length == 1) {
        this.data.params.suStoreId = this.data.userId[0]
      } else if (this.data.userId.length == 2) {
        // this.data.params.suStoreId = this.data.userId[0]
        this.data.params.suDepartmentId = this.data.userId[1]
      } else if (this.data.userId.length == 3) {
        // this.data.params.suStoreId = this.data.userId[0]
        // this.data.params.suDepartmentId = this.data.userId[1]
        this.data.params.popUserId = this.data.userId[2]
      }
      this.getDataList()
    },
    dateChange(){//日期改变时查询
      if(this.data.time == null) {
        this.data.params.startTime = ''
        this.data.params.endTime = ''
        this.data.params.cursor = 1
        this.getDataList()
      }else{
        this.data.params.startTime = `${this.data.time[0]}`
        this.data.params.endTime = `${this.data.time[1]}`
        this.data.params.cursor = 1
        this.getDataList()
      }
    },
    handleCurrentChange(cursor){//点击更改点前页数
      this.data.params.cursor = cursor;
      this.getDataList()
    },
    handleSizeChange(limit){//点击更改显示条数
      this.data.params.limit = limit;
      this.getDataList()
    },
    currentChange(row){
      this.rowData = row
      console.log(row)
    },
  //会员信息
    vipInformation(row){
      this.$axios({
        method: 'get',
        url: `/hotel/shortRentRenter/${row.jsrrId}`,
      }).then(res => {
        if (res.data.result) {
          for (let icon of res.data.result.shortRentContractDTOS) {
            let a = icon.hsAddCommunity ? `${icon.hsAddCommunity}\u3000` : ''
            let b = icon.hsRoomType ? `${icon.hsRoomType}\u3000` : ''
            let c = icon.hsAddDoorplateno ? `${icon.hsAddDoorplateno}` : ''
            icon.address = a + b + c
          }
          for (let i in res.data.result) {
            for (let k in this.vip.params) {
              if (i == k) {
                this.vip.params[k] = res.data.result[i]
              }
            }
          }
        }
        this.vip.dialogVip = true
      }).catch(err => {
        this.isError(err,this)
      })
    },
    changeVipRowData(state){
      let data = this.getIndex(this.dataList,this.rowData,state,this)
      if(!data) {
        return
      }
      this.rowData = data
      this.$refs.highLightRow.setCurrentRow(data)
      this.vipInformation(data)
    },
    vipCurrentChange(row){//选中行
      this.vip.rowData = row
    },
    closeDialogVip(){
      this.vip.params = {
        jcuType: '',//渠道类型
        jcuGroupType: '',//群体分类
        cardNumber: '',//会员卡号
        popName: '',//客户姓名
        popNameRemark: '',//备注
        popIdcardType: '',//证件类型
        popIdcard: '',//证件号码
        popBirth: '',//生日
        popIdcardAddress: '',//户籍地址
        popSex: '',//性别
        popNation: '',//民族
        popTelephone: '',//联系电话
        popImgPath: '',//图片
        jsrrCheckInNum: '',//入住次数
        popInnerCreditLevel: '',//内信用
        popOuterCreditLevel: '',//外信用
        integral: '',//会员积分
        licensePlate: '',//车牌号码
        weixin: '',//微信绑定
        blackList: '',//黑名单
        initialSource: '',//初始来源
        balance: '',//会员余额
        shortRentContractDTOS: [],
      }
    },
  //订单详情
    nextOrPrev(row) {
      this.$refs.vipHighLightRow.setCurrentRow(row);
    },
    orderDetailClose() {
      this.orderDetailDialog = false
    },
  },
}
</script>
<style lang="scss" scoped>
  .screenInput{
    width: 125px;
  }
  .dialog >>> .el-dialog__body{
    height: 486px;
    overflow: auto;
  }
  p{
    margin: 0;
    padding-left: 10px;
    font-size: 16px;
  }
  /deep/.is-leaf,.cell{
    text-align: center;
  }
</style>

